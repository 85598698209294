.testimonial {
  height: 50%;
  padding: 30px;
  border: 1px solid grey;
  margin-bottom: 50px;
}

.banner img {
  width: 100%;
}

.comments {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comment-user {
  width: 50%;
  height: 100%;
  border: 1px solid #4ab9f8;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .testimonial h1 {
    font-size: 18px;
    display: flex;
  }
  .banner img {
    width: 180%;
  }
}
