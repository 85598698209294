body {
  background-color: #ffffff;
}

a {
  text-decoration: none;
}

.header-info {
  /* width: 99.3%; */
  height: auto;
  width: auto;
  padding: 8px;
  /* padding-left: 100px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-search {
  /* width: 100%; */
  height: 40px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 200px;
}
#header-search {
  width: 460px;
  height: 100%;
  border-radius: 5px;
  border: none;
  color: #4ab9f8;
  border: 2px solid #4ab9f8;
  font-size: 18px;
  padding: 8px;
}

.header-contact {
  width: 100%;
  height: 50px;
  padding: 5px;
  /* padding-left: 50px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-location {
  /* width: 45px; */
  height: 40px;
}

.header-phone {
  width: 35px;
  height: 30px;
  margin-left: 10px;
  padding-right: 5px;
}

.header-contact-links:hover,
.header-phone:hover,
.header-location:hover {
  filter: invert(53%) sepia(38%) saturate(3805%) hue-rotate(159deg)
    brightness(91%) contrast(101%);
  transition: all 0.1s ease-in-out;
  /* background-color: transparent; */
}
.header-contact-links::after,
.header-phone::after,
.header-location::after {
  transition: all 0.1s ease-in-out;
  /* background-color: transparent; */
}

.social-media {
  /* background-color: red; */
  width: 300px;
  height: 10px;
  display: flex;
  justify-content: space-around;
}
.social-media a img {
  width: 100px;
  display: flex;
  justify-content: space-between;
  width: 30px;
  height: 30px;
}
.social-media img:hover {
  filter: invert(73%) sepia(16%) saturate(6593%) hue-rotate(172deg)
    brightness(99%) contrast(97%);
  transition: all 0.2s ease-in-out;
}

.navbar {
  background-color: #f8f8f8;
  /* width: %; */
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  border: 0.5px solid grey;
}

.logo {
  /* background-color: red; */
  width: 20%;
  height: 90%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}
.logo-cefise {
  width: 40%;
}
.logo-cefise:hover {
  transition: transform 0.2s;
  transform: scale(1.04);
}
.cefise {
  font-family: 'Be Vietnam Pro';
  font-size: 50px;
  -webkit-text-stroke: 1px black;
  color: #4ab9f8;
}
.cefise:hover {
  font-family: 'Be Vietnam Pro';
  font-size: 50px;
  -webkit-text-stroke: 1px #4ab9f8;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.links {
  /* background-color: red; */
  width: 50%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto' sans-serif;
  font-weight: 600;
}

.text-links,
.text-links-main {
  color: #272a31;
}
.text-links-main:hover,
.text-links:hover {
  color: #4ab9f8;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.text-links-main:focus {
  color: #4ab9f8;
  background-color: transparent;
}

.login {
  /* background-color: red; */
  width: 6%;
  display: flex;
  font-family: 'Roboto' sans-serif;
  font-weight: 600;
  color: #272a31;
  justify-content: space-between;
  margin-right: 10px;
}
.login img {
  width: 30px;
  height: 30px;
}

#menu-desplegable {
  width: 40%;
  display: none;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* Smartphones (portrait & landscape) */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .header-contact-links {
    width: 100%;
    font-size: 15px;
  }

  .logo-cefise {
    width: 170%;
  }

  .cefise {
    padding-left: 120px;
  }
  .header-info {
    /* width: 99.3%; */
    height: auto;
    width: auto;
    padding: 8px;
    /* padding-left: 100px; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .header-info {
    /* width: 99.3%; */
    height: auto;
    width: auto;
    padding: 15px;
    /* padding-left: 100px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-search {
    /* width: 100%; */
    height: 40px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 200px;
    padding-left: 10px;
  }
  #header-search {
    width: 310px;
    height: 100%;
    border-radius: 5px;
    border: none;
    color: #4ab9f8;
    border: 2px solid #4ab9f8;
    font-size: 18px;
    padding: 8px;
    margin-bottom: 50px;
  }
  .links {
    display: none;
  }
  .links #menu-active {
    position: fixed;
    display: inline-block;
    width: 100%;
    height: 100vh;
    /* background-color: #272a31; */
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all 0.5s;
  }

  .header-contact {
    width: 600px;
    height: auto;
    padding: 5px;
    /* padding-left: 50px; */
    margin-bottom: 40px;
    font-size: 10px;
  }
  #menu-desplegable {
    /* width: 110%; */
    display: flex;
    filter: invert(53%) sepia(38%) saturate(3805%) hue-rotate(159deg)
      brightness(91%) contrast(101%);
    justify-self: end;
  }
}
/* Smartphones (landscape) */
@media only screen and (min-width: 321px) {
}
/* Smartphones (portrait) */
@media only screen and (max-width: 320px) {
}
/* iPads (portrait & landscape) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}
/* iPads (landscape) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}
/* iPads (portrait) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}
/* Ordenadores de sobremesa y portátiles */
@media only screen and (min-width: 1224px) {
}
/* Pantallas grandes */
@media only screen and (min-width: 1824px) {
}
