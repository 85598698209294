.container-contactanos {
  /* height: 700px; */
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  justify-content: space-between;
  /* padding: 15px; */
}
.contactanos-texto {
  display: flex;
  /* background-color: red; */
}
.contactanos-parrafo {
  width: 80%;
  /* background-color: blue; */
}
.contactanos-parrafo h1,
.contactenos-mensaje h1 {
  margin-left: 15px;
  font-family: 'Roboto', sans-serif;
  color: #4ab9f8;
}

.container---one {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  /* background-color: #4ab9f8; */
  position: absolute;
  top: 23%;
}
.contactanos-form {
  width: 60%;
  /* height: 80%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
}
.contactanos-form p {
  text-align: justify;
}
.contactanos-form input {
  width: 347px;
  height: 40px;
  border-radius: 5px;
  border: 0.5px solid rgb(233, 227, 227);
  margin: 5px;
  padding: 5px;
}
.contactanos-form input[type='textarea'] {
  width: 93%;
  height: 100px;
}
.contactanos-button {
  width: 150px;
  height: 50px;
  border-radius: 5px;
  margin: 5px;
  align-self: flex-start;
  background-color: #4ab9f8;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: white;
  border: none;
}
.contactanos-button:hover {
  box-shadow: 1px 1px 3px 3px;
  transition: transform 0.2s;
  transform: scale(1.04);
  background-color: white;
  color: #4ab9f8;
}
.container---two {
  width: 50%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  /* background-color: green; */
  /* padding: 25px; */
  margin-top: 15px;
}

.contactanos-ubicacion {
  width: 70%;
  height: 250px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 3px #dbe2e6;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: 120%;
}
.contactanos-telefono {
  width: 70%;
  height: 270px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 3px #dbe2e6;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: 120%;
}

.contactanos-ubicacion h3 {
  width: 100px;
  height: 400px;
  background-color: grey;
  border-radius: 50%;
  color: grey;
  padding: 5px;
}
.contactanos-telefono h3 {
  width: 100px;
  height: 400px;
  background-color: grey;
  border-radius: 50%;
  color: grey;
  padding: 5px;
}

.contactanos-ubicacion h2,
.contactanos-telefono h2 {
  color: #4ab9f8;
}

.contactanos-ubicacion:hover,
.contactanos-telefono span:hover {
  color: #badff5;
  transition: all 0.5s ease-in-out;
}

#ico-transform {
  width: 30%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .contactanos-texto {
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .contactanos-texto {
    width: 100%;
  }

  .contactanos-form {
    width: 100%;
  }
  .container---one {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    /* background-color: #4ab9f8; */
    position: absolute;
    top: 870px;
    /* margin-bottom: 1000px; */
  }
  .container---two {
    width: 50%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    /* background-color: green; */
    /* padding: 25px; */
    margin-top: 15px;
  }
  .container---two {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    /* background-color: green; */
    /* padding: 25px; */
    margin-top: 15px;
  }
  .contactenos-mensaje {
    margin-bottom: 450px;
  }
}
