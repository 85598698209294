@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap');

.container-cefise {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 20px;
  /* background: linear-gradient(#d6eefc, white); */
  overflow: hidden;
  /* border: 5px solid black; */
  padding-top: 15px;
}

.img-cefise {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
}
.img-cefise:hover {
  transition: transform 0.2s;
}

.img-cefise img {
  width: 32%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 15px 15px 15px 15px;
}
.img-cefise img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.05);
}

.container--one {
  /* background-color: red; */
  height: 100%;
}
h1 {
  font-family: 'Roboto', sans-serif;
}

.container--two {
  /* background-color: green; */
  height: 100%;
  border: 1px solid #808080;
}

.container--three {
  /* background-color: blue;   */
  height: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .img-cefise {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* background-color: red; */
  }
  .img-cefise img {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px 15px 15px 15px;
  }
}
