.container-trayectoria {
  width: 100%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: red; */
}
.satisfaccion {
  margin-bottom: 50px;
}
.container-trayectoria h1 {
  font-family: 'Be Vietnam Pro';
  font-size: 60px;
  font-weight: 900;
  text-align: center;
  color: #4ab9f8;
  -webkit-text-stroke: 1px black;
  text-transform: uppercase;
}
.linea-trayectoria {
  /* width: 100%; */
  /* margin: auto; */
  background-color: grey;
}
.bar-trayectoria {
  width: 100%;
  /* height: 450px; */
  margin: auto;
  /* background-color: #bedcee; */
  /* opacity: 0.4; */
}
.bar-trayectoria img {
  width: 98%;
  height: 80%;
  display: flex;
  /* align-items: center; */
  justify-content: end;
  padding: 10px;
  border-radius: 15px;
  /* opacity: 0.4; */
}

.trayectoria-texto {
  height: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}
.trayectoria-texto h2 {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #4ab9f8;
  font-size: 40px;
}

.trayectoria-parrafo {
  display: flex;
  flex-direction: column;
  font-size: 120%;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: blue; */
}

.trayectoria-images {
  display: flex;
  justify-content: space-between;
}
.trayectoria-images img {
  width: 33%;
  border-radius: 15px;
}
.trayectoria-images img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.03);
  transition: transform 0.2s;
}
.trayectoria-images::after {
  transition: transform 0.2s;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-trayectoria h1 {
    font-family: 'Be Vietnam Pro';
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #4ab9f8;
    -webkit-text-stroke: 1px black;
    text-transform: uppercase;
  }
  .trayectoria-parrafo h2,
  .satisfaccion h2 {
    font-size: 150%;
  }
  .trayectoria-images {
    display: flex;
    flex-wrap: wrap;
  }
  .trayectoria-images img {
    width: 100%;
    padding-bottom: 5px;
  }
}
