.menu-desplegable {
  width: 100vw;
  height: 100vh;
  background-color: rgb(238, 232, 232);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu-desplegable img {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 10%;
}
.menu-desplegable a {
  color: #4ab9f8;
}
.menu-desplegable a:hover {
  color: #4ab9f8;
  text-decoration: line-through;
}
