.container-fisio {
  height: 50%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 40px;
  font-size: 120%;
  text-align: justify;
  /* background-color: red; */
}
.container-fisio h3 {
  display: flex;
  justify-content: center;
  color: #4ab9f8;
  font-size: 30px;
  /* background-color: red; */
}

.fisioterapia {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* background-color: red; */
  padding: 13px;
}

.container-one img {
  margin-top: 5px;
  width: 50%;
  height: 40%;
  border-radius: 15px;
  /* border: 1px solid black; */
  margin: auto;
}
.container-one {
  display: flex;
  overflow: hidden;
  padding-bottom: 20px;
}
.container-one img:hover {
  /* filter: grayscale(80%); */
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.03);
  transform: scale(1.05);
}

.container-two {
  display: flex;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
}
.container-two img {
  width: 70%;
  height: 40%;
  border-radius: 15px;
  /* border: 1px solid black; */
  margin: auto;
  display: flex;
}

.container-two img:hover {
  /* filter: grayscale(80%); */
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.03);
  transform: scale(1.05);
}

.container-three {
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-size: 25px;
}
.container-three img {
  width: 70%;
  margin: auto;
}

.container-three img:hover {
  /* filter: grayscale(80%); */
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.03);
  transform: scale(1.05);
}

.container-three p ul li:hover {
  color: #4ab9f8;
  transition: all 0.3s ease-in-out;
}
.container-three p ul li::after {
  transition: all 0.3s ease-in-out;
}

p {
  padding-left: 20px;
  padding-right: 20px;
}
.active {
  color: #4ab9f8;
}
.noActive {
  color: black;
}

.linea-fisio {
  height: 0.5px;
  background-color: grey;
  padding: 0%;
  margin: 0px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-two {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 80%;
  }
  .container-two h3 {
    font-size: 140%;
  }
  .container-two img {
    width: 100%;
  }
  .container-three {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 80%;
  }
  .container-three h3 {
    font-size: 170%;
  }
  .container-three img {
    width: 110%;
  }
  .container-fisio {
    width: auto;
    /* height: 50%; */
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
    font-size: 120%;
    text-align: justify;
    /* background-color: red; */
  }
  .fisioterapia {
    width: 100%;
  }
  .container-one {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    font-size: 80%;
  }
  .container-one h3 {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    font-size: 170%;
  }
  .container-one img {
    margin-top: 5px;
    width: 300px;
    /* height: 40%; */
    border-radius: 15px;
    /* border: 1px solid black; */
    margin: auto;
  }
  .fisioterapia {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* background-color: red; */
    /* padding: 13px; */
  }
}
