.container-footer {
  width: 100%;
  /* height: 50%; */
  /* height: 300px; */
  background-color: #272a31;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-left: 10px; */
}
.footer {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 20px;
  align-items: center;
  /* background-color: red; */
}
.footer h3 {
  font-family: 'Be Vietnam Pro';
  font-weight: 900;
  font-size: 50px;
  -webkit-text-stroke: 1px black;
  text-transform: uppercase;
  transition: transform 0.2s;
}
.footer h3:hover {
  transform: scale(1.1);
  color: #4ab9f8;
}

.social-media-footer {
  /* background-color: blue; */
  width: 40%;
  display: flex;
  padding: 10px;
  margin-top: -15px;
  /* align-items: flex-start; */
  justify-content: space-evenly;
}
.phone-footer {
  width: 100%;
  display: flex;
  /* background-color: red; */
  justify-content: center;
  font-size: 16px;
}
.phone-footer span {
  transform: scale(1.1);
}
.phone-footer a {
  color: white;
}
.phone-footer a:hover {
  color: #4ab9f8;
  transform: scale(1.1);
  transition: transform 0.2s;
}

.info-footer {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  height: 80%;
  /* background-color: red; */
  padding: 10px;
}
.email {
  height: 35px;
  border-radius: 20px;
  border: none;
  padding: 3px;
  padding-left: 10px;
  background: #777777;
  color: white;
  box-shadow: 1px 1px 3px 3px #23252b;
  font-family: 'Roboto';
}
.button {
  height: 35px;
  border-radius: 20px;
  background-color: #4ab9f8;
  color: white;
  border: none;
  box-shadow: 1px 1px 3px 3px #23252b;
}

.linea-footer {
  width: 100%;
  height: 2px;
  background-color: white;
}
.derechos {
  display: flex;
  justify-content: center;
  padding: 10px;
  padding-left: 120px;
  font-family: 'Roboto', sans-serif;
}
.derechos:hover {
  color: #138dd4;
}

.social-media-footer a svg {
  color: white;
  transform: scale(1.8);
}
.phone-footer {
  transform: scale(1.3);
}
.social-media-footer a svg:hover {
  color: #4ab9f8;
  transform: scale(2.1);
  transition: transform 0.2s;
}
.logotipo-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding-left: 20px; */
  margin-bottom: 10px;
  padding-left: 80px;
  margin-right: 80px;
}
.politicas {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  /* margin-right: 80px; */
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: #138dd4;
}
.politicas span {
  padding-left: 80px;
}
.politicas span:hover {
  transition: transform 0.2s;
  transform: scale(1.1);
}
.logotipo-footer img {
  -webkit-filter: drop-shadow(2px 3px 4px white);
  filter: drop-shadow(2px 3px 4px white);
  width: 120px;
  margin-left: 110px;
  margin-bottom: 10px;
  transition: transform 0.2s;
}
.logotipo-footer img:hover {
  transform: scale(1.1);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
  }
  .footer h3 {
    font-family: 'Be Vietnam Pro';
    font-weight: 900;
    font-size: 40px;
    -webkit-text-stroke: 1px black;
    text-transform: uppercase;
    transition: transform 0.2s;
  }
  .logotipo-footer img {
    -webkit-filter: drop-shadow(2px 3px 4px white);
    filter: drop-shadow(2px 3px 4px white);
    width: 90px;
    /* margin-left: 110px; */
    margin-bottom: 10px;
    transition: transform 0.2s;
  }
  .logotipo-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding-left: 20px; */
    margin-bottom: 10px;
    padding-left: 10px;
    margin-right: 80px;
  }
  .phone-footer {
    width: 90%;
    /* height: 20px; */
    display: flex;
    /* background-color: red; */
    justify-content: center;
    font-size: 12px;
    padding-left: 5px;
  }
  .phone-footer a {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .derechos {
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-left: 80px;
    font-family: 'Roboto', sans-serif;
  }
  .politicas {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    /* margin-right: 80px; */
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #138dd4;
  }
  .politicas span {
    padding-left: 80px;
  }
}
