@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap');

a {
  text-decoration: none;
}

.service {
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* background-color: red; */
}

.service-links {
  width: 38%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #272a31;
  font-size: 18px;
}
a {
  width: 38%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto' sans-serif;
  font-weight: 600;
  color: #272a31;
  font-size: 18px;
}
.service-links a:hover {
  color: #4ab9f8;
  transition: all 0.3s ease-in-out;
}

.service p {
  margin-top: 8px;
  font-size: 45px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #272a31;
}

.active-links {
  color: #4ab9f8;
}

.no-active-links {
  color: #272a31;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .service {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .service-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 170px;
    /* background-color: red; */
    /* display: none; */
  }
}
