.container-card {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  /* background-color: red; */
}

.card {
  width: 250px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 5px #808080;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card:hover {
  box-shadow: 2px 2px 5px 5px #4ab9f8;
  transition: transform 0.2s;
  transform: scale(1.1);
}

.card-linea {
  margin: 15px;
  border: 1px solid grey;
}

.card img {
  margin: auto;
  margin-top: 15px;
  border-radius: 5px;
  width: 90%;
}
.servicios {
  width: 90%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding-top: 10px;
}
.category {
  font-family: 'Roboto' sans-serif;
  font-weight: 600;
  padding-bottom: 5px;
  color: grey;
}
.condition {
  font-family: 'Roboto', sans-serif;
  color: #4ab9f8;
}
.service {
  font-family: 'Roboto', sans-serif;
}
.category-list {
  text-align: center;
}

.card-icon {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  /* padding-bottom: 10px; */
}
.card-icon a {
  width: 15%;
}
#producto {
  width: 40px;
}
.card-button {
  display: flex;
  justify-content: center;
}
.card-button a {
  width: 90%;
  height: 35px;
  border-radius: 5px;
  background-color: #4ab9f8;
}
.card-button a {
  color: white;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 550;
  border: 0;
  margin: 10px;
  background-color: #4ab9f8;
  /* padding-left: 65px; */
}
.card-button a:hover {
  box-shadow: 1px 1px 3px 3px;
  background-color: white;
  color: #4ab9f8;
  transition: all 0.5s ease-in-out;
}
/* .card-button a button:hover {
  background-color: white;
  color: #4ab9f8;
} */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-card {
    margin-top: 150px;
  }
}

#card-backImg {
  width: 210px;
  height: 150px;
}
