#container {
  height: 50%;
  /* margin-bottom: 20px; */
  /* padding: 20px; */
  display: flex;
  /* background-color: red; */
  justify-content: space-between;
}

.perfil .perfil-cefise {
  /* background-color: red; */
  width: 80%;
  padding: 30px;
  margin-left: 10px;
  /* padding-left: 80px; */
  border-radius: 20%;
}

.presentacion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
}

.presentacion h1 {
  font-family: 'Roboto', sans-serif;

  font-weight: 700;
  font-size: 50px;
}

.cargo {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0;
}
.cargo #nombre {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 30px;
}
.cargo #cargo {
  font-family: 'Roboto';
  font-size: 20px;
}

.cargo #enlace {
  background-color: #272a31;
  font-family: 'Roboto' sans-serif;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: #4ab9f8;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .presentacion h1 {
    font-family: 'Roboto', sans-serif;

    font-weight: 700;
    font-size: 20px;
  }

  .perfil .perfil-cefise {
    /* background-color: red; */
    width: 80%;
    /* padding-right: 100px; */
    padding: 0px;
    margin-left: 20px;
    /* margin-right: 100px; */
  }
  .cargo #nombre {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    padding-right: 60px;
  }
  .cargo #cargo {
    font-family: 'Roboto';
    font-size: 15px;
  }
  .cargo #enlace {
    background-color: #272a31;
    font-family: 'Roboto' sans-serif;
    font-weight: 700;
    border-radius: 50px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: #4ab9f8;
    font-size: 10px;
  }
}
