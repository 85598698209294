	/*
  	Flaticon icon font: Flaticon
  	Creation date: 10/07/2018 06:45
  	*/

@font-face {
font-family: "Flaticon";
	src: url("../fonts/Flaticon.eot");
	src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Flaticon.woff") format("woff"),
	url("../fonts/Flaticon.ttf") format("truetype"),
	url("../fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	}
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-reception-bell:before { content: "\f100"; }
.flaticon-wedding:before { content: "\f101"; }
.flaticon-bachelorette-party:before { content: "\f102"; }